@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600;700&display=swap');

:root {
	--orange: #f79f1f;
	--text-color-1: #444;
	--text-color-2: #666;
	--bg-color-1: #fff;
	--bg-color-2: #eee;
	--box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	border: none;
	text-decoration: none;
	/* text-transform: capitalize; */
	/* transition: all 0.2s linear; */
}
input[type='date']::-webkit-calendar-picker-indicator {
	background-image: url('../assets/images/dateIcon.svg');
}
input[type='time']::-webkit-calendar-picker-indicator {
	background-image: url('https://www.pngitem.com/pimgs/m/31-312717_time-clock-icon-free-png-transparent-png.png');
}
/* input[value='']::-webkit-datetime-edit { color: transparent; } */
/* input[type='date']:focus::-webkit-datetime-edit { color: #000; } */
/* input[type=date] { color: transparent !important; }  */
/* input[type=date].active input[type=date] { color: inherit !important; } */
input[value='']::-webkit-datetime-edit {
	color: transparent;
}

html {
	/* font-size: 62.5%; */
	overflow-x: hidden;
	/* scroll-padding-top: 7rem; */
	/* scroll-behavior: smooth; */
}

/* html::-webkit-scrollbar {
	width: 0.8rem;
}

html::-webkit-scrollbar-track {
	background: transparent;
}

html::-webkit-scrollbar-thumb {
	background: rgb(57, 201, 187);
	border-radius: 5rem;
} */

body {
	/* background: var(--bg-color-2); */
	background-color: #ebedf078;
}

body.active {
	--text-color-1: #fff;
	--text-color-2: #eee;
	--bg-color-1: #333;
	--bg-color-2: #222;
	--box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.4);
}

section {
	padding: 2rem 7%;
}

.heading {
	text-align: center;
	padding-bottom: 2rem;
	color: rgb(239, 51, 57);
	font-size: 4rem;
	font-weight: 500;
}

.heading span {
	position: relative;
	z-index: 0;
}
.ln {
	width: 100%;
	float: left;
	height: 1px;
	background: #d0cfcf;
	position: relative;
}
.ln::before {
	content: '';
	float: left;
	height: 7px;
	width: 7px;
	border-radius: 50%;
	background: #d0cfcf;
	position: absolute;
	left: 0;
	bottom: -3px;
}
.ln::after {
	content: '';
	float: right;
	height: 7px;
	width: 7px;
	border-radius: 50%;
	background: #d0cfcf;
	position: absolute;
	right: 0;
	bottom: -3px;
}
.heading span::before {
	content: '';
	position: absolute;
	bottom: -2rem;
	left: 0;
	z-index: -1;
	background: yellow;
	height: 100%;
	width: 100%;
	clip-path: polygon(0 90%, 100% 75%, 100% 100%, 0% 100%);
}

.btn {
	margin-top: 1rem;
	display: inline-block;
	padding: 11px;
	font-size: 1.5rem;
	color: #fff;
	background: rgb(57, 201, 187);
	border-radius: 0.5rem;
	cursor: pointer;
}

.btn:hover {
	letter-spacing: 0.2rem;
}

.header {
	align-items: center;
	background: #fff;
	background: var(--bg-color-1);
	box-shadow: 0 0.5rem 1.5rem rgb(0 0 0 / 10%);
	box-shadow: var(--box-shadow);
	display: flex;
	justify-content: space-between;
	left: 0;
	padding-left: 20px;
	padding-right: 20px;
	position: absolute;
	right: 0;
	top: 30px;
	z-index: 1000;
	padding: 5px;
}

.header2 {
	display: none;
}
.header .logo {
	font-weight: bolder;
	font-size: 2.5rem;
	color: var(--text-color-1);
}

.header .logo i {
	color: rgb(57, 201, 187);
}

.header .search-form {
	background: var(--bg-color-2);
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	height: 4.5rem;
	width: 50rem;
}

.header .search-form input {
	height: 100%;
	width: 100%;
	background: none;
	text-transform: none;
	font-size: 1.5rem;
	color: var(--text-color-1);
	padding: 1rem;
}

.header .search-form label {
	font-size: 2rem;
	margin-right: 1.5rem;
	color: var(--text-color-1);
	cursor: pointer;
}

.header .search-form label:hover {
	color: rgb(57, 201, 187);
}

.header .icons div {
	height: 4.5rem;
	width: 4.5rem;
	line-height: 4.5rem;
	font-size: 2rem;
	border-radius: 0.5rem;
	margin-left: 0.5rem;
	background: var(--bg-color-2);
	color: var(--text-color-1);
	cursor: pointer;
	text-align: center;
}

.header .icons div:hover {
	color: #fff;
	background: rgb(239, 51, 57);
}

#search-btn {
	display: none;
}

.header .navbar {
	position: absolute;
	top: 115%;
	right: 7%;
	background: var(--bg-color-1);
	border-radius: 0.5rem;
	box-shadow: var(--box-shadow);
	width: 25rem;
	transform: scale(0);
	transform-origin: top right;
}

.header .navbar.active {
	transform: scale(1);
}

.header .navbar a {
	display: block;
	margin: 1rem;
	padding: 1rem;
	font-size: 1.5rem;
	color: var(--text-color-1);
	border-radius: 0.5rem;
}

.header .navbar a:hover {
	color: rgb(57, 201, 187);
	background: var(--bg-color-2);
	padding-left: 2rem;
}

.header .login-form {
	position: absolute;
	top: 115%;
	right: 7%;
	background: var(--bg-color-1);
	border-radius: 0.5rem;
	box-shadow: var(--box-shadow);
	/* width: 35rem; */
	padding: 2rem;
	transform: scale(0);
	transform-origin: top right;
}

.header .login-form.active {
	transform: scale(1);
}

.header .login-form .inputBox {
	margin-bottom: 1rem;
}

.header .login-form .inputBox span {
	font-size: 1.4rem;
	color: var(--text-color-2);
}

.header .login-form .inputBox input {
	font-size: 1.4rem;
	color: var(--text-color-1);
	border-radius: 0.5rem;
	padding: 1rem;
	background: var(--bg-color-2);
	width: 100%;
	text-transform: none;
	margin: 0.5rem 0;
}

.header .login-form .remember {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem 0;
}

.header .login-form .remember label {
	color: var(--text-color-2);
	font-size: 1.5rem;
	cursor: pointer;
}

.header .login-form .btn {
	width: 100%;
}

.header .login-form2 {
	position: absolute;
	top: 115%;
	right: 7%;
	background: var(--bg-color-1);
	border-radius: 0.5rem;
	box-shadow: var(--box-shadow);
	/* width: 35rem; */
	padding: 2rem;
	transform: scale(0);
	transform-origin: top right;
}

.header .login-form2.active2 {
	transform: scale(1);
}

.header .login-form2 .inputBox2 {
	margin-bottom: 1rem;
}

.header .login-form2 .inputBox2 span {
	font-size: 1.5rem;
	color: var(--text-color-2);
}

.header .login-form2 .inputBox2 input {
	font-size: 1.5rem;
	color: var(--text-color-1);
	border-radius: 0.5rem;
	padding: 1rem;
	background: var(--bg-color-2);
	width: 100%;
	text-transform: none;
	margin: 0.5rem 0;
}

.header .login-form2 .remember2 {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem 0;
}

.header .login-form2 .remember label {
	color: var(--text-color-2);
	font-size: 1.5rem;
	cursor: pointer;
}

.header .login-form2 .btn {
	width: 100%;
}
.home {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1.5rem;
	/* padding-top: 14rem; */
}

.home .image {
	flex: 1 1 30rem;
}

.home .image img {
	width: 100%;
}

.home .content {
	flex: 1 1 45rem;
}

.home .content h3 {
	color: var(--text-color-1);
	font-size: 3rem;
}

.home .content p {
	color: var(--text-color-2);
	font-size: 1.4rem;
	line-height: 1.7;
	padding: 1rem 0;
}

.form-container form {
	background: var(--bg-color-1);
	box-shadow: var(--box-shadow);
	border-radius: 0.5rem;
	padding: 2rem;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	align-items: center;
}

.form-container form .inputBox {
	flex: 1 1 23rem;
}

.form-container form .inputBox span {
	font-size: 1.7rem;
	color: var(--text-color-2);
}

.form-container form .inputBox input {
	font-size: 1.5rem;
	color: var(--text-color-1);
	background: var(--bg-color-2);
	border-radius: 0.5rem;
	margin: 0.5rem 0;
	text-transform: none;
	width: 100%;
	padding: 1rem;
}

.form-container form .btn {
	flex: 1 1 23rem;
}

.packages .box-container {
	display: grid;
	/* grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)); */
	gap: 1.5rem;
}

.packages .box-container .box {
	background: var(--bg-color-1);
	border-radius: 0.5rem;
	box-shadow: var(--box-shadow);
}

.packages .box-container .box .image {
	height: 15rem;
	width: 100%;
	/* padding: 1.5rem; */
	position: relative;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.packages .box-container .box .image img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	/* border-radius: 0.5rem; */
}

.packages .box-container .box .image h3 {
	position: absolute;
	top: 2.5rem;
	left: 2.5rem;
	font-size: 2rem;
	color: #fff;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 0.5rem;
	font-weight: lighter;
	padding: 0.5rem 1.5rem;
}

.packages .box-container .box .image h3 i {
	color: red;
}

.packages .box-container .box .content {
	padding: 1.5rem;
	padding-top: 0;
}

.packages .box-container .box .content .price {
	font-weight: 600;
	color: var(--text-color-1);
	font-size: 24px;
}

.packages .box-container .box .content .price span {
	font-weight: lighter;
	color: var(--text-color-2);
	font-size: 1.5rem;
	text-decoration: line-through;
}

.packages .box-container .box .content p {
	font-size: 1.5rem;
	padding: 0.5rem 0;
	color: var(--text-color-2);
	line-height: 1.7;
}

.services .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	gap: 1.5rem;
}

.services .box-container .box {
	background: var(--bg-color-1);
	border-radius: 0.5rem;
	box-shadow: var(--box-shadow);
	position: relative;
	padding: 2rem;
}

.services .box-container .box span {
	position: absolute;
	top: 0.5rem;
	right: 2rem;
	color: rgb(239, 51, 57);
	font-weight: bolder;
	font-size: 3rem;
}

.services .box-container .box i {
	height: 4rem;
	width: 4rem;
	line-height: 4rem;
	text-align: center;
	font-size: 1.5rem;
	color: #fff;
	background: rgb(239, 51, 57);
	border-radius: 50%;
	margin-bottom: 0.5rem;
}

.services .box-container .box h3 {
	color: black;
	/* font-size: 2.3rem; */
	padding: 0.7rem 0;
	font-weight: revert;
}

.services .box-container .box p {
	color: var(--text-color-2);
	/* font-size: 1.5rem; */
	line-height: 1.8;
}

.pricing .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	gap: 1.5rem;
}

.pricing .box-container .box {
	background: var(--bg-color-1);
	border-radius: 0.5rem;
	box-shadow: var(--box-shadow);
	padding: 2rem;
	text-align: center;
}

.pricing .box-container .box h3 {
	padding: 1rem;
	background: var(--bg-color-2);
	border-radius: 0.5rem;
	font-size: 2rem;
	color: var(--text-color-1);
}

.pricing .box-container .box .price {
	padding-top: 2rem;
}

.pricing .box-container .box .price span {
	font-size: 2rem;
	color: var(--text-color-2);
}

.pricing .box-container .box .price .amount {
	font-size: 5rem;
	color: var(--text-color-1);
	font-weight: bolder;
}

.pricing .box-container .box ul {
	padding: 0.5rem 0;
	list-style: none;
}

.pricing .box-container .box ul li {
	padding: 1rem 0;
	font-size: 1.7rem;
	color: var(--text-color-2);
}

.review .review-slider {
	box-shadow: var(--box-shadow);
}

.review .review-slider .slide {
	background: var(--bg-color-1);
	border-radius: 0.5rem;
	text-align: center;
	padding: 3rem;
}

.review .review-slider .slide img {
	height: 10rem;
	width: 10rem;
	border-radius: 50%;
	object-fit: cover;
	margin-bottom: 0.5rem;
}

.review .review-slider .slide h3 {
	font-size: 2.5rem;
	color: var(--text-color-1);
}

.review .review-slider .slide p {
	font-size: 1.6rem;
	color: var(--text-color-2);
	line-height: 1.7;
	padding: 1rem 0;
}

.review .review-slider .slide .stars {
	padding: 1rem 0;
	padding-bottom: 1.5rem;
}

.review .review-slider .slide .stars i {
	font-size: 2rem;
	color: rgb(57, 201, 187);
}

.swiper-pagination-bullet-active {
	background: rgb(57, 201, 187);
}

.contact form {
	max-width: 70rem;
	margin: 0 auto;
	text-align: center;
}

.contact form .inputBox {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.contact form textarea,
.contact form .inputBox input {
	width: 100%;
	padding: 1rem;
	font-size: 1.6rem;
	color: var(--text-color-1);
	margin: 0.7rem 0;
	background: var(--bg-color-1);
	box-shadow: var(--box-shadow);
	text-transform: none;
	border-radius: 0.5rem;
}

.contact form textarea {
	height: 20rem;
	resize: none;
}

.contact form .inputBox input {
	width: 49%;
}

.blogs .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	gap: 1.5rem;
}

.blogs .box-container .box {
	background: var(--bg-color-1);
	border-radius: 0.5rem;
	box-shadow: var(--box-shadow);
	padding: 2rem;
}

.blogs .box-container .box .image {
	height: 20rem;
	width: 100%;
	overflow: hidden;
	border-radius: 0.5rem;
	background: var(--bg-color-2);
}

.blogs .box-container .box .image img {
	height: 100%;
	width: 100%;
}

.blogs .box-container .box:hover .image img {
	transform: scale(1.2);
}

.blogs .box-container .box .content {
	padding-top: 1rem;
}

.blogs .box-container .box .content h3 {
	font-size: 2rem;
	color: var(--text-color-1);
	line-height: 1.5;
}

.blogs .box-container .box .content .icons {
	display: flex;
	justify-content: space-between;
	border-top: 0.1rem solid var(--text-color-2);
	margin-top: 2rem;
	padding-top: 1.5rem;
}

.blogs .box-container .box .content .icons a {
	font-size: 1.5rem;
	color: var(--text-color-2);
}

.blogs .box-container .box .content .icons a:hover {
	color: rgb(57, 201, 187);
}

.blogs .box-container .box .content .icons a i {
	padding-right: 0.5rem;
	color: rgb(57, 201, 187);
}

.footer {
	background: var(--bg-color-1);
}

.footer .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
	gap: 1.5rem;
}

.footer .box-container .box h3 {
	font-size: 2.5rem;
	color: var(--text-color-1);
	padding: 1rem 0;
}

.footer .box-container .box a {
	display: block;
	font-size: 1.5rem;
	color: var(--text-color-2);
	padding: 1rem 0;
}

.footer .box-container .box a i {
	padding-right: 0.5rem;
	color: rgb(239, 51, 57);
}

.footer .box-container .box a:hover i {
	padding-right: 2rem;
}

.footer .credit {
	font-size: 2rem;
	text-align: center;
	border-top: 0.1rem solid var(--text-color-2);
	padding: 1rem;
	padding-top: 2rem;
	margin-top: 2rem;
	color: var(--text-color-2);
}

.footer .credit span {
	color: rgb(57, 201, 187);
}

/* media queries  */

@media (max-width: 991px) {
	html {
		font-size: 55%;
	}

	.header {
		padding: 1.5rem 2rem;
	}

	section {
		padding: 2rem;
	}
}

@media (max-width: 768px) {
	.header .navbar {
		right: 2rem;
	}

	.header .login-form {
		right: 2rem;
	}

	#search-btn {
		display: inline-block;
	}

	.header .search-form {
		position: absolute;
		top: 115%;
		right: 2rem;
		background: var(--bg-color-1);
		border-radius: 0.5rem;
		width: 90%;
		box-shadow: var(--box-shadow);
		transform: scale(0);
		transform-origin: top right;
	}

	.header .search-form.active {
		transform: scale(1);
	}
}

@media (max-width: 450px) {
	html {
		font-size: 50%;
	}

	.contact form .inputBox input {
		width: 100%;
	}
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

::selection {
	color: #fff;
	background: #664aff;
}

/* .wrapper {
	max-width: 450px;
	margin: 150px auto;
} */

.wrapper .search-input {
	background: #fff;
	width: 100%;
	border-radius: 5px;
	position: relative;
	box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
}

.search-input input {
	height: 55px;
	width: 100%;
	outline: none;
	border: none;
	border-radius: 5px;
	padding: 0 60px 0 20px;
	font-size: 18px;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.search-input.active input {
	border-radius: 5px 5px 0 0;
}

.search-input .autocom-box {
	padding: 0;
	opacity: 0;
	pointer-events: none;
	max-height: 280px;
	overflow-y: auto;
}

.search-input.active .autocom-box {
	padding: 10px 8px;
	opacity: 1;
	pointer-events: auto;
}

.autocom-box li {
	list-style: none;
	padding: 8px 12px;
	display: none;
	width: 100%;
	cursor: default;
	border-radius: 3px;
}

.search-input.active .autocom-box li {
	display: block;
}
.autocom-box li:hover {
	background: #efefef;
}

.search-input .icon {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 55px;
	width: 55px;
	text-align: center;
	line-height: 55px;
	font-size: 20px;
	color: #644bff;
	cursor: pointer;
}

.menu {
	display: block;
	margin: 0 auto;
	position: relative;
	/* width: 200px; */
	list-style: none;
}

.menu > li > a {
	background: white;
	color: black;
	display: block;
	padding: 12px 20px;
	text-align: center;
	text-decoration: none;
	border-radius: 2;
}

.menu ul {
	height: 283px;
	opacity: 1;
	transform: translateY(0);
	width: '350px';
	z-index: 15;
	display: block;
	position: absolute;
	background: white;
	/* display: none; */
}
@media screen and (max-width: 950px) {
	.menu ul {
		width: 78%;
	}
}

.menu li:hover ul {
	/* height: 283px;
	opacity: 1;
	transform: translateY(0);
	width: '350px';
	z-index: 15;
	display: block; */
}

.menu ul a {
	color: #000;
	display: block;
	padding: 5px 20px;
}
.newddf {
	margin: 15px 0;

	display: flex;
	-webkit-box-pack: center;
	/* -ms-flex-pack: center; */
	/* -webkit-justify-content: center; */
	justify-content: center;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
	align-items: center;
	-webkit-box-pack: center;
	-webkit-box-align: center;
	display: inline-flex;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	outline: 0;
	border: 0;
	cursor: pointer;
	user-select: none;
	vertical-align: middle;
	-webkit-appearance: none;
	font-weight: 400;
	line-height: 1.66;
	letter-spacing: 0.03333em;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	padding: 0;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	margin: 0 2px;
	font-size: 16px;
}
/*the container must be positioned relative:*/
.custom-select {
	position: relative;
	/* font-family: Arial; */
}

.custom-select select {
	display: none; /*hide original SELECT element:*/
}

.select-selected {
	background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
	position: absolute;
	content: '';
	top: 14px;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
	border-color: transparent transparent #fff transparent;
	top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
	color: #ffffff;
	padding: 8px 16px;
	border: 1px solid transparent;
	border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
	cursor: pointer;
	user-select: none;
}

/*style items (options):*/
.select-items {
	position: absolute;
	background-color: DodgerBlue;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
	display: none;
}

.select-items div:hover,
.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}
input[value='0000-00-00']::-webkit-datetime-edit {
	color: transparent;
}
.menu2 {
	display: block;
	margin: 0 auto;
	position: relative;
	/* width: 200px; */
	list-style: none;
}

.menu2 > li > a {
	background: white;
	color: black;
	display: block;
	padding: 12px 20px;
	text-align: center;
	text-decoration: none;
	border-radius: 2;
}

.menu2 ul {
	/* height: 283px; */
	opacity: 1;
	transform: translateY(0);
	width: '350px';
	z-index: 15;
	display: block;
	position: absolute;
	background: white;
	/* display: none; */
}
@media screen and (max-width: 950px) {
	.menu2 ul {
		width: 78%;
	}
}

.menu2 li:hover ul {
	/* height: 283px;
	opacity: 1;
	transform: translateY(0);
	width: '350px';
	z-index: 15;
	display: block; */
}

.menu2 ul a {
	color: #000;
	display: block;
	padding: 5px 20px;
}
