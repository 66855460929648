.main-wrapper {
	margin: 0 auto;
	width: 960px;
	padding: 20px 20px;
	border: 1px solid #ccc;
	border-radius: 8px;
}
.maind {
	/* border-bottom: 1px solid #ccc; */
	margin: 0px 0 20px 0;
	padding: 0 0 5px 0;
}
.clearfix {
	*zoom: 1;
}
.clearfix:after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	line-height: 0;
	overflow: hidden;
	font-size: 0;
	visibility: hidden;
}
.main1 {
	border-bottom: 1px dashed #ccc;
	padding: 0 0 5px;
}
.main1 .box1 {
	width: 33%;
	float: left;
	font-size: 16px;
	color: #4f6bb0;
}
.main1 .box2 {
	width: 33%;
	text-align: center;
	float: left;
	font-size: 16px;
	color: #4f6bb0;
}
.main1 .box3 {
	width: 33%;
	text-align: center;
	float: left;
	font-size: 16px;
	color: #4f6bb0;
}
/* h2 {
	font-size: 1.5em;
} */
.maind .grid-02 {
	width: 260px;
	text-align: center;
	margin: 0 20px 0 0;
	float: left;
}
.maind .grid-02 p {
	padding: 0 0 5px 0;
	font-size: 14px;
	margin: 0;
}
.maind .grid-03 {
	width: 300px;
	font-size: 16px;
	text-align: right;
	float: right;
}
.maind .grid-03 p {
	margin: 10px 0 0;
}
b {
	font-weight: normal;
	color: #000;
}
.table {
	width: 100%;
	margin-bottom: 20px;
}
/* table {
	
}
th {
	
}
td {
	padding: 8px;
	line-height: 1.42857143;
} */
.main5 {
	border-top: 3px solid #000;
}
.grid-box {
	padding: 10px;
	border: 1px solid #4f6bb0;
}
.grid-box ul {
	padding: 0;
	font-size: 14px;
}
.grid-box ul li {
	float: left;
	list-style: none;
}
.cal-02 {
	width: 28%;
	text-align: center;
}
.bod {
	border: none !important;
	padding-top: 0 10px !important;
}
.main4 {
	padding: 10px 0 20px 0;
}
.main3 {
	font-size: 14px;
}
